import React from 'react'



class UsecaseOf extends React.Component {


  render() {

    return (
      <section className="usecase bgremove pt-100 mb-0 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor"> Use Case</span> of</span>
              Crypto OTC Exchange Development</h3>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12  text-center" >
              <img className='lazyload' width="547px" height="408px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/usecase-of-our-otc-crypto-exchange.png" alt="Use Case of OTC Crypto Exchange image1" />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph" ><b>Commodities Trading -</b> Commodities can be converted into cryptocurrencies
                and then traded on the OTC exchange platform.
              </p>
              <p className="pharagraph" ><b>Peer-To-Peer Trading -</b> Direct trading between individuals can be facilitated
                for cryptocurrencies and tokens.
              </p>
              <p className="pharagraph" ><b>Liquidity -</b>  OTC exchanges can be used to raise quick liquidity by direct selling of cryptocurrencies between the interested parties.
              </p>
              <p className="pharagraph" ><b>Revenue Generation -</b>   There is a great opportunity for revenue for the crypto OTC exchange owner as a greater number of people buy and sell cryptocurrencies and tokens.
              </p>
              <p className="pharagraph mb-0" ><b>E-Commerce -</b>  Since third parties are not required to sell cryptocurrencies,
                e-commerce transactions can be incorporated with greater speed.
              </p>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default UsecaseOf