import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">What is</span>
                OTC Crypto Exchange?</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-lg-8 col-md-12 left-side order2" >
              <p className="pharagraph" >An OTC crypto exchange is a trading platform that facilitates direct trading of cryptocurrencies between buyers and sellers without the involvement of a centralized exchange. This OTC platforms allow for private transactions, particularly suited for large-volume trades or bulk trading of digital assets. This method offers greater flexibility in all transaction sizes.
              </p>
              <p className="pharagraph" >For businesses, OTC cryptocurrency exchange development can be highly profitable, leveraging revenue streams such as trading fees and more. Developing a secure and efficient crypto OTC trading platform is crucial for ensuring user trust and operational success. Consider partnering with a reputable OTC crypto exchange development company like Coinsclone, renowned for delivering tailored solutions that prioritize security, speed, and user experience.
              </p>
            </div>
            <div className="col-lg-4 col-md-12 right-side order1" >
              <img className='lazyload' width="397px" height="330px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/what-is-otc-crypto-exchange.png" alt="OTC Crypto Exchange Image1" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Whatis