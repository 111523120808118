import * as React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"
import BannerSection from "../components/Otc/Banner"
import Whatis from "../components/Otc/Whatis"
import WhyShould from "../components/Otc/WhyShould"
import TopFeatures from "../components/Otc/TopFeatures"
import HowOur from "../components/Otc/HowOur"
import CoreFeatures from "../components/Otc/CoreFeatures"
import SecurityofOur from "../components/Otc/SecurityofOur"
import UsecaseOf from "../components/Otc/UsecaseOf"
import WhyChoose from "../components/Otc/WhyChoose"
import Testimonial from "../components/whitelabelpage/Testimonial"
import PortfolioSection from "../components/whitelabelpage/Portfolio"
import DevApproach from "../components/Otc/DevApproach"
import TechStack from "../components/Otc/TechStack"
import FaqSection from "../components/Otc/FaqSection"
import UserFriendly from "../components/Cryptoexchange/UserFriendly"
import ReviewNew from "../components/Landing/Review-new"
import CaseStudy from "../components/Otc/CaseStudy"




const Otc = () => (
  <section className="dev-page">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <title>OTC Crypto Exchange Development | Crypto OTC Trading Software</title>
        <meta name="description" content="Launch a feature-rich OTC crypto exchange development solution offering secure, seamless trading. Get our custom and White label OTC software to trade across countries." />
        <meta name="keywords" content="OTC Crypto Exchange Development, OTC Crypto Exchange Software, OTC Exchange Development, Crypto OTC Trading Platform Development, OTC Trading Software, Over-the-Counter Exchange Software, OTC Crypto Exchange Development Services, White Label OTC Exchange Development Company" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="OTC Crypto Exchange Development | Crypto OTC Trading Software" />
        <meta property="og:description" content="Launch a feature-rich OTC crypto exchange development solution offering secure, seamless trading. Get our custom and White label OTC software to trade across countries." />
        <meta property="og:url" content="https://www.coinsclone.com/otc-crypto-exchange-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/otc-new/otc-crypto-exchange-development.png" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Launch a feature-rich OTC crypto exchange development solution offering secure, seamless trading. Get our custom and White label OTC software to trade across countries." />
        <meta name="twitter:title" content="OTC Crypto Exchange Development | Crypto OTC Trading Software" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/otc-new/otc-crypto-exchange-development.png" />
        <link rel="canonical" href="https://www.coinsclone.com/otc-crypto-exchange-development/" />
      </Helmet>
      <BannerSection />
      <div className="breadcrumb">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | OTC Crypto Exchange Development</span>
        </div>
      </div>
      <Whatis />
      <CaseStudy />
      <WhyShould />
      <TopFeatures />
      <HowOur />
      <CoreFeatures />
      <SecurityofOur />
      <UsecaseOf />
      <PortfolioSection />
      <WhyChoose />
      <DevApproach />
      <Testimonial />
      <ReviewNew />
      <TechStack />
      <FaqSection />
      <UserFriendly />
      
    </Layout>
  </section>
)

export default Otc
