import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'

class TopFeatures extends React.Component {

  state = {
    modal: false
  }

  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() {
    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="heading-h3 bluecolor">Top Features</span>
              Of Our OTC Crypto Exchange Development</h2>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">User to Admin Trading</h4>
                <p className="pharagraph">Don’t wait for other users to execute your trades. Exchange your cryptocurrencies
                  directly with the admin for the best available market rate.
                </p>
                <div className="text-left">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1" >
              <img className='lazyload'  width="565px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/user-to-admin-trading.png" alt="User to Admin Trading image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1" >
              <img className='lazyload'  width="559px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/multicurrency-wallet.png" alt="Multicurrency Wallet image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Multicurrency Wallet</h4>
                <p className="pharagraph">Our OTC software is compatible to support all types of altcoins and tokens. Also, the users can select their desired network during the deposit & withdrawal.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">User Friendly UI/UX</h4>
                <p className="pharagraph">We make sure to provide a simple, neat & clean user interface which makes them
                  connect with the platform in real time experience.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1" >
              <img className='lazyload'  width="586px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/user-friendly-ux-ui.png" alt="User Friendly UI/UX image1" />
            </div>
          </div>
          <div className="row table-content orderflex">
            <div className="col-md-12 col-lg-6  order1" > 
              <img className='lazyload'  width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/multi-layered-security.png" alt="Multi Layered Security image1" />
            </div>
            <div className="col-md-12 col-lg-6 order2 ">
              <div className="top-right" >
                <h4 className="heading-h4">Multi Layered Security</h4>
                <p className="pharagraph">Our software is pre-loaded with all the up to date security mechanisms like XSS, CSRF, DDoS, DoS & SSRF protections. In addition to it, Google authentication, Recovery keys, Anti-phishing code, KYC/AML are the standard features.</p>
                <div className="text-left mobile-hide">
                  <ButtonComponent />
                </div>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h4 className="heading-h4">Instant Buy/Sell</h4>
                <p className="pharagraph">No longer you need not want to wait for hours to exchange your cryptocurrencies. All the transactions are automated for the best market price so get your pieces of cryptos ultra-fast!</p>
                <div className="text-left">
                  <ButtonComponent />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 right-side order1" >
              <img className='lazyload' width="536px" height="313px" src="https://coinsclone.mo.cloudinary.net/images/otc-new/instant-buy-sell.png" alt="Instant Buy/Sell image1" />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default TopFeatures