import React from 'react'


const WhyChoose = () => {


  return (
    <section className="choosedesire ychoose otc pt-100 mb-0">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <h3 className="heading-h2"><span className="heading-h3"><span className="bluecolor">Why Choose </span>us for</span>
              OTC crypto exchange development?</h3>
            <p className="pharagraph">Our OTC crypto exchange development service is the finest and top-notch service that you find in the crypto industry. We offer a single package in which you can find all that you need for developing an outstanding OTC cryptocurrency exchange platform.
            </p>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload' width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/otc/hardcore-blockchain-developers.svg" alt="Hardcore Blockchain Developers image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Hardcore Blockchain Developers
                </h3>
                <p className="pharagraph">
                  Our development team has been working on various blockchain projects for many years having great hands-on experience.

                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload' width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/otc/whitelabel-platform.svg" alt="Whitelabel Platform image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Whitelabel Platform
                </h3>
                <p className="pharagraph">
                We will deliver a completely customizable OTC crypto exchange software that you will be able to alter in whatsoever manner.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex firstblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload' width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/otc/360-degree-support.svg" alt="360-Degree Support image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  360-Degree Support
                </h3>
                <p className="pharagraph">
                From design to development to deployment to marketing, we can provide you complete support to make your OTC crypto exchange a great success.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload' width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/otc/no-unpleasant-surprises.svg" alt="No Unpleasant Surprises image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  No Unpleasant Surprises
                </h3>
                <p className="pharagraph">
                  When you work with us you will never be caught by surprise in terms of pricing and increasing deadlines.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-lg-flex lastblck">
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload' width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/otc/scalable-development.svg" alt="Scalable Development image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Scalable Development
                </h3>
                <p className="pharagraph">
                Whatever is your budget, whatever is the extent of features and capabilities you want to incorporate, we can scale accordingly.
                </p>
              </div>
            </div>
          </div>
          <div className="col pt-md-3" >
            <div className="innerBlock">
              <div className="icn-left">
                <div className="icn-img">
                  <img className='lazyload' width="32px" height="32px" src="https://coinsclone.mo.cloudinary.net/images/otc/Multiplatform.svg" alt="Multiplatform image1" />
                </div>
              </div>
              <div className="content-rght">
                <h3>
                  Multiplatform
                </h3>
                <p className="pharagraph">
                Your users will be able to use your OTC crypto trading platform from multiple devices with different operating systems.
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>
  )
}

export default WhyChoose